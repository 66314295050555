import { useSolutionList } from "../../queryHooks/useSolutionList";
import { LoadingSpinner } from "../LoadingSpinner";
import { SolutionList } from "./SolutionList";

export const SolutionListContainer = () => {
  const { isLoading, data: solutions } = useSolutionList();

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return <SolutionList solutions={solutions!} />;
};
