import React from "react";
import { SubmissionDetails } from "./SubmissionDetails";
import { Submission } from "../../types/SubmissionType";
import { useSubmission } from "../../queryHooks/useSubmission";
import { LoadingSpinner } from "../LoadingSpinner";

interface Props {
  submissionId: Submission["id"];
}

export const SubmissionDetailsContainer: React.FC<Props> = ({
  submissionId,
}) => {
  const { isLoading, data: submission } = useSubmission(submissionId);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>{submission ? <SubmissionDetails submission={submission} /> : null}</>
  );
};

SubmissionDetailsContainer.defaultProps = {};
