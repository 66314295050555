import { Box, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useSolutionPayment } from "../../../../queryHooks/useSolutionPayment";
import { Solution } from "../../../../types/Solution";
import { LoadingSpinner } from "../../../LoadingSpinner";
import { SolutionPaymentDetails } from ".";

interface Props {
  solutionSlug: Solution["slug"];
}

export const SolutionPaymentContainer: React.FC<Props> = ({ solutionSlug }) => {
  const { isLoading, data } = useSolutionPayment(solutionSlug);

  if (isLoading) {
    return (
      <Box my={6}>
        <LoadingSpinner />
      </Box>
    );
  }

  return (
    <>
      <Text variant="accent" mb={0} mt={4}>
        Payment
      </Text>
      {data ? (
        <SolutionPaymentDetails payment={data} solutionSlug={solutionSlug} />
      ) : (
        <Text color="gray">
          To enable payment on your solution, please contact us.
        </Text>
      )}
    </>
  );
};
