import ApiClient from "../apiClient";
import { SubmissionFile } from "../types/SubmissionFile";
import { SubmissionNote } from "../types/SubmissionNote";
import { Submission } from "../types/SubmissionType";

export const getSubmissionsForFirm = async (): Promise<Submission[]> => {
  return (await ApiClient.getInstance().get("/firm-app/submissions")).data;
};

export const getSubmissionById = async (
  submissionId: Submission["id"],
): Promise<Submission> => {
  return (
    await ApiClient.getInstance().get(`/firm-app/submissions/${submissionId}`)
  ).data;
};

export const getSubmissionNoteById = async (
  submissionId: Submission["id"],
): Promise<SubmissionNote> => {
  return (
    await ApiClient.getInstance().get(
      `/firm-app/submissions/${submissionId}/notes`,
    )
  ).data;
};

export const saveSubmissionNoteById = async (
  submissionId: Submission["id"],
  note: string,
): Promise<SubmissionNote> => {
  return (
    await ApiClient.getInstance().post(
      `/firm-app/submissions/${submissionId}/notes`,
      { note },
    )
  ).data;
};

export const getSubmissionFiles = async (
  submissionId: Submission["id"],
): Promise<SubmissionFile[]> => {
  return (
    await ApiClient.getInstance().get(
      `/firm-app/submissions/${submissionId}/templated-documents`,
    )
  ).data;
};

export const getSubmissionFile = async (
  submissionId: Submission["id"],
  filename: string,
): Promise<SubmissionFile> => {
  return (
    await ApiClient.getInstance().get(
      `/firm-app/submissions/${submissionId}/templated-documents/${filename}`,
    )
  ).data;
};

export const uploadSubmissionFile = async (
  submissionId: Submission["id"],
  file: File,
): Promise<void> => {
  const formData = new FormData();
  formData.append("file", file);
  await ApiClient.getInstance().post(
    `/firm-app/submissions/${submissionId}/templated-documents`,
    formData,
  );
};

export const deleteSubmissionfile = async (
  submissionId: Submission["id"],
  filename: string,
): Promise<void> => {
  await ApiClient.getInstance().delete(
    `/firm-app/submissions/${submissionId}/templated-documents/${filename}`,
  );
};

export const closeSubmission = async (
  submissionId: Submission["id"],
): Promise<void> => {
  await ApiClient.getInstance().put(
    `/firm-app/submissions/${submissionId}/close`,
    {},
  );
};

export const approveSubmission = async (
  submissionId: Submission["id"],
): Promise<void> => {
  await ApiClient.getInstance().put(
    `/firm-app/submissions/${submissionId}/approve`,
    {},
  );
};

export const getSubmissionAttachments = async (
  submissionId: Submission["id"],
): Promise<SubmissionFile[]> => {
  return (
    await ApiClient.getInstance().get(
      `/firm-app/submissions/${submissionId}/attachments`,
    )
  ).data;
};
