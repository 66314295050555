import React from "react";
import { useCloseSubmission } from "../../queryHooks/useSubmission";
import { Submission } from "../../types/SubmissionType";
import { SubmissionUtils } from "../../utils/SubmissionUtils";
import { CloseSubmission } from "./CloseSubmission";

interface Props {
  submission: Submission;
}

export const CloseSubmissionContainer: React.FC<Props> = ({ submission }) => {
  const mutation = useCloseSubmission(submission.id);

  const handleOnClose = () => {
    mutation.mutate();
  };

  return (
    <CloseSubmission
      onClick={handleOnClose}
      isLoading={mutation.isLoading}
      disabled={SubmissionUtils.isCompleted(submission)}
    />
  );
};

CloseSubmissionContainer.defaultProps = {};
