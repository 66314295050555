import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Flex, Heading, LinkBox, LinkOverlay } from "@chakra-ui/react";
import { Submission } from "../../types/SubmissionType";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";
import { SubmissionStatusBadge } from "../SubmissionStatusBadge";
import { DateFormat } from "../../utils/DateFormat";

interface Props {
  submissions: Submission[];
}

export const SubmissionList: React.FC<Props> = ({ submissions }) => {
  return (
    <Flex flexDirection="column">
      <Flex justifyContent="space-between" mb={10}>
        <Box>
          <Heading as="h3">Submissions</Heading>
        </Box>
      </Flex>
      <TableContainer bg="white">
        <Table>
          <Thead>
            <Tr>
              <Th>updated at</Th>
              <Th>solution</Th>
              <Th>client</Th>
              <Th>status</Th>
            </Tr>
          </Thead>
          <Tbody>
            {submissions.map((s) => (
              <Tr key={s.id} _hover={{ bgColor: "#fff7f3" }}>
                <Td p={0}>
                  <LinkBox p={4} key={s.id} _hover={{ bgColor: "#fff7f3" }}>
                    <LinkOverlay as={RouterLink} to={`/submissions/${s.id}`}>
                      {DateFormat.format(s.updatedAt)}
                    </LinkOverlay>
                  </LinkBox>
                </Td>
                <Td p={0}>
                  <LinkBox p={4} key={s.id} _hover={{ bgColor: "#fff7f3" }}>
                    <LinkOverlay as={RouterLink} to={`/submissions/${s.id}`}>
                      <span>{s.solution.name}</span>
                    </LinkOverlay>
                  </LinkBox>
                </Td>
                <Td p={0}>
                  <LinkBox p={4} key={s.id} _hover={{ bgColor: "#fff7f3" }}>
                    <LinkOverlay as={RouterLink} to={`/submissions/${s.id}`}>
                      <span>{s.client.name}</span>
                    </LinkOverlay>
                  </LinkBox>
                </Td>
                <Td p={0}>
                  <LinkBox p={4} key={s.id} _hover={{ bgColor: "#fff7f3" }}>
                    <LinkOverlay as={RouterLink} to={`/submissions/${s.id}`}>
                      <SubmissionStatusBadge submissionStatus={s.status} />
                    </LinkOverlay>
                  </LinkBox>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Flex>
  );
};

SubmissionList.defaultProps = {
  submissions: [],
};
