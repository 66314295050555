import {
  Avatar,
  Box,
  Flex,
  Icon,
  IconButton,
  useToken,
} from "@chakra-ui/react";
import React from "react";
import { NavLink } from "react-router-dom";
import { BsJournalBookmark } from "react-icons/bs";
import { HiOutlineInboxArrowDown } from "react-icons/hi2";
import { SlSettings } from "react-icons/sl";
import { LogoutButton } from "../LogoutButton";

interface Props {}

export const Sidebar: React.FC<Props> = () => {
  const [activeColor] = useToken("colors", ["active"]);
  return (
    <Flex pos="sticky" flexDir="column" bgColor="white" h="100vh">
      <Flex flexDir="column" justifyContent="space-between" h="100vh">
        <Flex
          p="5%"
          mr={1}
          flexDir="column"
          alignItems="center"
          as="nav"
          h="100vh"
        >
          <NavLink to="/submissions">
            <IconButton
              aria-label="logo"
              data-testid="logo"
              backgroundColor="white"
              my={3}
              _hover={{ backgroundColor: "white" }}
              _active={{ backgroundColor: "white" }}
              icon={
                <Avatar size="sm" src="../logo.png" backgroundColor="white" />
              }
            />
          </NavLink>
          <NavLink
            to="/solutions"
            style={({ isActive }) => ({
              borderLeft: isActive ? "2px solid" : "",
              borderLeftColor: isActive ? activeColor : "",
            })}
          >
            <IconButton
              aria-label="solutions"
              backgroundColor="white"
              _hover={{ backgroundColor: "white" }}
              _active={{ backgroundColor: "white" }}
              icon={
                <Icon
                  boxSize="5"
                  as={BsJournalBookmark}
                  backgroundColor="white"
                />
              }
            />
          </NavLink>
          <NavLink
            to="/submissions"
            style={({ isActive }) => ({
              borderLeft: isActive ? "2px solid" : "",
              borderLeftColor: isActive ? activeColor : "",
            })}
          >
            <IconButton
              aria-label="submissions"
              backgroundColor="white"
              _hover={{ backgroundColor: "white" }}
              _active={{ backgroundColor: "white" }}
              icon={
                <Icon
                  boxSize="5"
                  as={HiOutlineInboxArrowDown}
                  backgroundColor="white"
                />
              }
            />
          </NavLink>
          <NavLink
            to="/company-settings"
            style={({ isActive }) => ({
              borderLeft: isActive ? "2px solid" : "",
              borderLeftColor: isActive ? activeColor : "",
            })}
          >
            <IconButton
              aria-label="company-settings"
              backgroundColor="white"
              _hover={{ backgroundColor: "white" }}
              _active={{ backgroundColor: "white" }}
              icon={
                <Icon boxSize="5" as={SlSettings} backgroundColor="white" />
              }
            />
          </NavLink>
        </Flex>
        <Box pb={2}>
          <LogoutButton />
        </Box>
      </Flex>
    </Flex>
  );
};
