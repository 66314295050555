import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  deleteSubmissionfile,
  getSubmissionFiles,
  uploadSubmissionFile,
} from "../services/SubmissionsService";
import { Submission } from "../types/SubmissionType";

const key = (submissionId: Submission["id"]) => [
  "submission",
  "files",
  submissionId,
];

export const useSubmissionFiles = (submissionId: Submission["id"]) =>
  useQuery(key(submissionId), () => getSubmissionFiles(submissionId), {
    staleTime: 600000, //expires after 10mins
  });

/* istanbul ignore next */
export const useSubmissionFileUpload = (submissionId: Submission["id"]) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (newFile: File) => uploadSubmissionFile(submissionId, newFile),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: key(submissionId),
          refetchType: "active",
        });
      },
    },
  );
  return mutation;
};

/* istanbul ignore next */
export const useSubmissionFileDelete = (submissionId: Submission["id"]) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (filename: string) => deleteSubmissionfile(submissionId, filename),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: key(submissionId),
          refetchType: "active",
        });
      },
    },
  );
  return mutation;
};
