import ApiClient from "../apiClient";
import { Email } from "../types/Email";
import { Solution } from "../types/Solution";

export const getEmailsForSolution = async (
  solutionSlug: Solution["slug"],
): Promise<any> => {
  return (
    await ApiClient.getInstance().get(
      `/firm-app/solutions/${solutionSlug}/emails`,
    )
  ).data;
};

export const saveSolutionEmail = async (
  solutionSlug: Solution["slug"],
  data: Email,
): Promise<void> => {
  await ApiClient.getInstance().put(
    `/firm-app/solutions/${solutionSlug}/emails`,
    data,
  );
};
