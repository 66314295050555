import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
} from "@chakra-ui/react";

interface Props {
  title: string;
  content: string;
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

export const ConfirmationDialog: React.FC<Props> = ({
  title,
  content,
  isOpen,
  onConfirm,
  onCancel,
}) => {
  return (
    <>
      {isOpen ? (
        <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onCancel}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{title}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text>{content}</Text>
            </ModalBody>

            <ModalFooter>
              <Button variant="secondary" mr={3} onClick={onCancel}>
                Cancel
              </Button>
              <Button variant="primary" onClick={onConfirm}>
                Confirm
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      ) : null}
    </>
  );
};

ConfirmationDialog.defaultProps = {};
