import {
  AspectRatio,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Text,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useSaveFirmBranding } from "../../queryHooks/useFirmBranding";
import { Firm } from "../../types/Firm";
import { ColorPicker } from "./_/ColorPicker";
import { CompanyBrandingLogo } from "./_/CompanyBrandingLogo";

interface Props {
  brand: Firm;
}

const colorPickerValidationRule = {
  required: true,
  minLength: 3,
};

export const CompanyBranding: React.FC<Props> = ({ brand }) => {
  const mutation = useSaveFirmBranding();
  const toast = useToast();

  const { handleSubmit, control, register } = useForm<Firm>({
    defaultValues: {
      primaryColor: brand.primaryColor,
      secondaryColor: brand.secondaryColor,
      backgroundColor: brand.backgroundColor,
      headingBackgroundColor: brand.headingBackgroundColor,
      headingFontColor: brand.headingFontColor,
      successColor: brand.successColor,
      address: brand.address,
      termUrl: brand.termUrl,
      privacyUrl: brand.privacyUrl,
      email: brand.email,
      publicEmail: brand.publicEmail,
      stripe: brand.stripe,
    },
  });

  const onSubmit: SubmitHandler<Firm> = (data) => {
    mutation
      .mutateAsync(data)
      .then((res) => {
        if (res.status === 202) {
          toast({
            title: `Email confirmation required, please check the inbox of ${data.publicEmail}`,
            status: "info",
            isClosable: true,
          });
        }
      })
      .catch((error) => {
        toast({
          title: "An error occurred, please try again",
          status: "error",
          isClosable: true,
        });
      });
  };

  return (
    <>
      <AspectRatio w="400px" my="-8">
        <iframe
          src={"https://player.vimeo.com/video/820393433?h=b69970c64f"}
          title="Changing Branding and Colours"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </AspectRatio>
      <Flex flexDirection="column" my={5}>
        <Heading fontSize="md" as="h5">
          Logo
        </Heading>
        <CompanyBrandingLogo
          currentLogo={brand.logo}
          backgroundColor={brand.headingBackgroundColor}
        />
      </Flex>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex flexDirection="column" my={5}>
          <Heading fontSize="md" as="h5">
            Colours
          </Heading>
          <Flex my={5} gap={4}>
            <Controller
              render={({ field }) => (
                <ColorPicker
                  name={field.name}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  label="Primary"
                />
              )}
              control={control}
              rules={colorPickerValidationRule}
              name="primaryColor"
            />
            <Controller
              render={({ field }) => (
                <ColorPicker
                  name={field.name}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  label="Secondary"
                />
              )}
              control={control}
              rules={colorPickerValidationRule}
              name="secondaryColor"
            />
            <Controller
              render={({ field }) => (
                <ColorPicker
                  name={field.name}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  label="Background"
                />
              )}
              control={control}
              rules={colorPickerValidationRule}
              name="backgroundColor"
            />
            <Controller
              render={({ field }) => (
                <ColorPicker
                  name={field.name}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  label="Heading"
                />
              )}
              control={control}
              rules={colorPickerValidationRule}
              name="headingBackgroundColor"
            />
            <Controller
              render={({ field }) => (
                <ColorPicker
                  name={field.name}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  label="Heading font"
                />
              )}
              control={control}
              rules={colorPickerValidationRule}
              name="headingFontColor"
            />
            <Controller
              render={({ field }) => (
                <ColorPicker
                  name={field.name}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  label="Success"
                />
              )}
              control={control}
              rules={colorPickerValidationRule}
              name="successColor"
            />
          </Flex>
        </Flex>

        <Flex flexDirection="column" my={5} gap={4} w={["100%", "50%"]}>
          <Heading fontSize="md" as="h5">
            Details
          </Heading>
          <FormControl isRequired>
            <FormLabel>Address</FormLabel>
            <Input {...register("address", { required: true, minLength: 3 })} />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Terms and Conditions</FormLabel>
            <Input {...register("termUrl", { required: true })} />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Privacy</FormLabel>
            <Input {...register("privacyUrl", { required: true })} />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Email</FormLabel>
            <Input {...register("email", { required: true })} />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Public email</FormLabel>
            <Input {...register("publicEmail", { required: true })} />
            <Text fontSize={"2xs"} color={"gray.600"}>
              Changing this field will require email confirmation
            </Text>
          </FormControl>
          <FormControl isRequired isDisabled>
            <FormLabel>Stripe account</FormLabel>
            <Input
              {...register("stripe", { required: true, disabled: true })}
            />
          </FormControl>
        </Flex>
        <Button
          type="submit"
          variant="primary"
          size="lg"
          w="auto"
          isLoading={mutation.isLoading}
        >
          Save
        </Button>
      </form>
    </>
  );
};
