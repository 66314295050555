import { Button } from "@chakra-ui/react";
import React from "react";
import { Submission } from "../../types/SubmissionType";

interface Props {
  submission: Submission;
}

export const ViewSubmission: React.FC<Props> = ({ submission }) => {
  // eslint-disable-next-line
  const link = `${process.env.REACT_APP_CLIENT_BASE_URL}/solutions/${submission.solution.slug}/submissions/${submission.id}`;

  return (
    <Button variant="primary" as="a" href={link} target="_blank">
      View Submission
    </Button>
  );
};
