export default {
  styles: {
    global: {
      "*": {
        boxSizing: "border-box",
      },
      "html, body, #__next": {
        height: "100%",
        width: "100%",
        margin: 0,
        backgroundColor: "#F8F8F8",
      },
    },
  },
  components: {
    ModalBody: {
      baseStyle: {
        bg: "red",
        overflow: "auto",
        maxHeight: { base: "calc(100vh - 150px)", lg: "calc(100vh - 350px)" },
      },
    },
    Text: {
      variants: {
        accent: {
          fontSize: "18px",
          lineHeight: "32px",
          fontWeight: "bold",
          mt: 1,
          mb: 2,
        },
      },
    },
    Badge: {
      variants: {
        green: {
          borderRadius: 15,
          bg: "teal.500",
          color: "white",
          px: 2,
          py: 1,
          textTransform: "none",
        },
        red: {
          borderRadius: 15,
          bg: "pink.50",
          borderColor: "pink.600",
          border: "1px solid",
          color: "pink.600",
          px: 2,
          py: 1,
          textTransform: "none",
        },
      },
    },
    Button: {
      variants: {
        primary: {
          backgroundColor: "primary",
          color: "white",
          borderRadius: "30px",
          px: 8,
          border: "solid 2px transparent",
          size: "lg",
          _hover: {
            _disabled: {
              backgroundColor: "primary",
            },
          },
          _active: {
            border: "solid 2px",
          },
        },
        secondary: {
          backgroundColor: "white",
          color: "primary",
          borderColor: "primary",
          borderRadius: "30px",
          border: "solid 2px",
          px: 8,
          _active: {
            border: "solid 2px",
            borderColor: "primary",
          },
        },
      },
    },
  },
  // only light mode - address a dark mode later?
  config: {
    initialColorMode: "light",
    useSystemColorMode: false,
  },
  colors: {
    primary: "#BE0D69",
    error: "#f4005e",
    success: "#319795",
    active: "#EC7C1B",
    pink: {
      600: "#BE0D69",
      50: "#FFF2F7",
    },
    teal: {
      500: "#319795",
      50: "#D1F0EF",
    },
    grey: {
      300: "#F3F3F3",
      600: "#B7B7B7",
      700: "#7A7A7A",
      900: "#444444",
    },
    blue: {
      600: "#2C6CB0",
      500: "#3182CE",
      300: "#EBF8FF",
    },
  },
  // fonts: {
  // }
};
