import {
  Box,
  Flex,
  Heading,
  LinkBox,
  LinkOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import { SolutionBasicInformation } from "../../types/Solution";
import { DateFormat } from "../../utils/DateFormat";
import { Link as RouterLink } from "react-router-dom";
import { CheckIcon, CloseIcon } from "@chakra-ui/icons";

interface Props {
  solutions: SolutionBasicInformation[];
}

export const SolutionList: React.FC<Props> = ({ solutions }) => {
  return (
    <Flex flexDirection="column">
      <Flex justifyContent="space-between" mb={10}>
        <Box>
          <Heading as="h3">Solutions</Heading>
        </Box>
      </Flex>
      <TableContainer bg="white">
        <Table>
          <Thead>
            <Tr>
              <Th>name</Th>
              <Th>requires review</Th>
              <Th>published at</Th>
            </Tr>
          </Thead>
          <Tbody>
            {solutions.map((s) => (
              <Tr key={s.id} _hover={{ bgColor: "#fff7f3" }}>
                <Td p={0}>
                  <LinkBox p={4}>
                    <LinkOverlay as={RouterLink} to={`/solutions/${s.slug}`}>
                      {s.name}
                    </LinkOverlay>
                  </LinkBox>
                </Td>
                <Td p={0}>
                  <LinkBox p={4}>
                    <LinkOverlay as={RouterLink} to={`/solutions/${s.slug}`}>
                      <span>
                        {s.requiresReview ? <CheckIcon /> : <CloseIcon />}
                      </span>
                    </LinkOverlay>
                  </LinkBox>
                </Td>
                <Td p={0}>
                  <LinkBox p={4}>
                    <LinkOverlay as={RouterLink} to={`/solutions/${s.slug}`}>
                      <span>{DateFormat.format(s.publishedAt)}</span>
                    </LinkOverlay>
                  </LinkBox>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Flex>
  );
};
