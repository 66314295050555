import React, { useEffect, useState } from "react";
import {
  getSubmissionNoteById,
  saveSubmissionNoteById,
} from "../../../../services/SubmissionsService";
import { SubmissionNote } from "../../../../types/SubmissionNote";
import { Submission } from "../../../../types/SubmissionType";
import { SubmissionNotes } from "./SubmissionNotes";

export interface SubmissionNotesProps {
  submissionId: Submission["id"];
  getNote?: (submissionId: Submission["id"]) => Promise<SubmissionNote>;
  saveNote?: (
    submissionId: Submission["id"],
    note: string,
  ) => Promise<SubmissionNote>;
}

const EMPTY_NOTE: SubmissionNote = {
  id: -1,
  note: "",
};

export const SubmissionNotesContainer: React.FC<SubmissionNotesProps> = ({
  submissionId,
  getNote,
  saveNote,
}) => {
  const [note, setNote] = useState<SubmissionNote>(EMPTY_NOTE);
  const [saving, setSaving] = useState(false);

  const handleOnSave = async (value: string) => {
    setSaving(true);
    const result = await saveNote!(submissionId, value);
    setNote(result);
    setSaving(false);
  };

  useEffect(() => {
    async function fetch() {
      try {
        const result = await getNote!(submissionId);
        setNote(result);
      } catch (error) {}
    }
    fetch();
  }, [submissionId, getNote]);

  return (
    <SubmissionNotes onSave={handleOnSave} value={note} isSaving={saving} />
  );
};

SubmissionNotesContainer.defaultProps = {
  getNote: getSubmissionNoteById,
  saveNote: saveSubmissionNoteById,
};
