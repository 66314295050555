import { useAuth0 } from "@auth0/auth0-react";
import { Button, Icon, IconButton } from "@chakra-ui/react";
import React from "react";
import { SlLogout } from "react-icons/sl";

interface Props {
  returnToUrl?: string;
}

export const LogoutButton: React.FC<Props> = ({ returnToUrl }) => {
  const { logout } = useAuth0();

  const handleClick = () => {
    logout({ returnTo: returnToUrl });
  };

  return (
    <IconButton
      aria-label="company-settings"
      onClick={handleClick}
      backgroundColor="white"
      _hover={{ backgroundColor: "white" }}
      _active={{ backgroundColor: "white" }}
      icon={<Icon boxSize="5" as={SlLogout} backgroundColor="white" />}
      data-testid="logout"
    />
  );
};

LogoutButton.defaultProps = {
  returnToUrl: window.location.origin,
};
