import { Flex, FlexProps } from "@chakra-ui/react";
import React from "react";

interface Props {
  children?: React.ReactNode;
}

export const Paper: React.FC<Props & FlexProps> = ({ children, ...props }) => {
  return <Flex {...props}>{children}</Flex>;
};

Paper.defaultProps = {
  height: "100%",
  borderRadius: "20px",
  flexDirection: "column",
  bg: "white",
  p: 4,
};
