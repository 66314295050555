import { useSubmissionList } from "../../queryHooks/useSubmissionList";
import { LoadingSpinner } from "../LoadingSpinner";
import { SubmissionList } from "./SubmissionList";

export const SubmissionListContainer = () => {
  const { isLoading, data: submissions } = useSubmissionList();

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return <SubmissionList submissions={submissions!} />;
};

SubmissionListContainer.defaultProps = {};
