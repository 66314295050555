import ApiClient from "../apiClient";
import { SolutionPayment } from "../types/Payment";
import { Solution } from "../types/Solution";

export const getPaymentForSolution = async (
  solutionSlug: Solution["slug"],
): Promise<SolutionPayment> => {
  return (
    await ApiClient.getInstance().get(
      `/firm-app/solutions/${solutionSlug}/payment`,
    )
  ).data;
};

export const saveSolutionPayment = async (
  solutionSlug: Solution["slug"],
  data: SolutionPayment,
): Promise<void> => {
  await ApiClient.getInstance().put(
    `/firm-app/solutions/${solutionSlug}/payment`,
    data,
  );
};
