import { Center, Flex, Text } from "@chakra-ui/react";
import { isEmpty, nth, split } from "lodash";
import React from "react";
import { SubmissionFile } from "../../types/SubmissionFile";
import { FilePreview } from "./_/FilePreview";

interface Props {
  attachments: SubmissionFile[];
  onClick: (file: SubmissionFile) => void;
}

export const AttachmentFiles: React.FC<Props> = ({ attachments, onClick }) => {
  const handleOnClick = (file: SubmissionFile) => () => {
    onClick(file);
  };

  return (
    <>
      <Text variant="accent">Attachments</Text>
      {!isEmpty(attachments) ? (
        <Flex wrap="wrap">
          {attachments.map((a) => (
            <FilePreview
              key={a.filename}
              filename={nth(split(a.filename, "/"), 1)!}
              fileUrl={a.preSignedUrl}
              onClick={handleOnClick(a)}
            />
          ))}
        </Flex>
      ) : (
        <Center>
          <Text>No files found</Text>
        </Center>
      )}
    </>
  );
};
