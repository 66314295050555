import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

interface Props {}

export const HomePage: React.FC<Props> = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/submissions", { replace: true });
    // eslint-disable-next-line
  }, []);

  return null;
};

HomePage.defaultProps = {};
