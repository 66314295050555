import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Switch,
  Text,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useSaveSolutionPayment } from "../../../../queryHooks/useSolutionPayment";
import {
  CurrencyCode,
  PaymentLocation,
  SolutionPayment,
} from "../../../../types/Payment";
import { Solution } from "../../../../types/Solution";

interface Props {
  payment: SolutionPayment;
  solutionSlug: Solution["slug"];
}

export const SolutionPaymentDetails: React.FC<Props> = ({
  payment,
  solutionSlug,
}) => {
  const mutation = useSaveSolutionPayment(solutionSlug);
  const toast = useToast();

  const { handleSubmit, formState, register, reset } = useForm<SolutionPayment>(
    {
      defaultValues: {
        enabled: payment?.enabled,
        amount: payment?.amount / 100,
        currency: payment?.currency,
        location: payment?.location,
        description: payment?.description,
      },
    },
  );

  const onSubmit: SubmitHandler<SolutionPayment> = async (data) => {
    data.amount = data.amount * 100;
    await mutation.mutate(data);

    toast({
      title: "",
      description: "Payment settings saved",
      status: "success",
      duration: 5000,
      isClosable: true,
      position: "bottom-right",
    });

    reset({}, { keepValues: true });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl display="flex" alignItems="center" my={2}>
        <FormLabel htmlFor="payment-enabled" mb="0">
          Enabled
        </FormLabel>
        <Switch
          id="payment-enabled"
          defaultChecked={payment.enabled}
          {...register("enabled")}
        />
      </FormControl>
      <FormControl mb={4}>
        <FormLabel htmlFor="payment-amount" mb="0">
          Amount
        </FormLabel>
        <Flex>
          <Select
            {...register("currency")}
            defaultValue={payment.currency}
            width={"125px"}
            mr={2}
          >
            <option value={CurrencyCode.NZD}>NZD $</option>
            <option value={CurrencyCode.AUD}>AUD $</option>
            <option value={CurrencyCode.USD}>USD $</option>
            <option value={CurrencyCode.GBP}>GBP £</option>
          </Select>
          <Input
            id="payment-amount"
            {...register("amount", { required: true, valueAsNumber: true })}
          />
        </Flex>
      </FormControl>
      <FormControl mb={4}>
        <FormLabel htmlFor="payment-amount" mb="0">
          Paywall Location
        </FormLabel>
        <Select {...register("location")} defaultValue={payment.location}>
          <option value={PaymentLocation.PRE}>Before starting</option>
          <option value={PaymentLocation.POST}>Before submitting</option>
        </Select>
      </FormControl>

      <FormControl mb={4}>
        <FormLabel htmlFor="payment-description" mb="0">
          Payment Description
        </FormLabel>
        <Input id="payment-description" {...register("description")} />
      </FormControl>

      <Flex justifyContent={"right"}>
        <Button
          variant="primary"
          type={"submit"}
          isLoading={mutation.isLoading}
          isDisabled={!formState.isDirty}
        >
          Save Payment
        </Button>
      </Flex>
    </form>
  );
};
