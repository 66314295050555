import { useQuery } from "@tanstack/react-query";
import { getSubmissionAttachments } from "../services/SubmissionsService";
import { Submission } from "../types/SubmissionType";

const key = (submissionId: Submission["id"]) => [
  "submission",
  "attachments",
  submissionId,
];

export const useSubmissionAttachments = (submissionId: Submission["id"]) =>
  useQuery(key(submissionId), () => getSubmissionAttachments(submissionId), {
    staleTime: 600000, //expires after 10mins
  });
