import { useQuery } from "@tanstack/react-query";
import { getSubmissionsForFirm } from "../services/SubmissionsService";

const key = () => ["submission", "list"];

export const useSubmissionList = () =>
  useQuery(key(), getSubmissionsForFirm, {
    staleTime: 30000, // expires after 30 secs
    refetchOnMount: "always",
  });
