import { Button } from "@chakra-ui/react";
import React from "react";

interface Props {
  isLoading?: boolean;
  disabled?: boolean;
  onClick: () => void;
}

export const CloseSubmission: React.FC<Props> = ({
  isLoading,
  disabled,
  onClick,
}) => {
  return (
    <Button
      variant="secondary"
      onClick={disabled ? () => {} : onClick}
      isLoading={isLoading}
      disabled={disabled}
    >
      Close Submission
    </Button>
  );
};

CloseSubmission.defaultProps = {
  isLoading: false,
  disabled: false,
};
