import { Divider, Flex, Link, Text } from "@chakra-ui/react";
import React from "react";
import { Submission } from "../../../../types/SubmissionType";
import { DateFormat } from "../../../../utils/DateFormat";
import { SubmissionStatusBadge } from "../../../SubmissionStatusBadge";

interface Props {
  submission: Submission;
}

export const SubmissionBasicDetails: React.FC<Props> = ({ submission }) => {
  return (
    <>
      <Text variant="accent" mb={3}>
        Submission details
      </Text>
      <Flex my={2}>
        <Text>Date</Text>
        <Text ml={4} color="gray">
          {DateFormat.format(submission.createdAt)}
        </Text>
      </Flex>
      <Flex my={1}>
        <Text>Status</Text>
        <Text ml={4}>
          <SubmissionStatusBadge submissionStatus={submission.status} />
        </Text>
      </Flex>

      <Divider my={3} />

      <Text variant="accent" my={3}>
        Client details
      </Text>
      <Flex my={2}>
        <Text>Full name</Text>
        <Text ml={4} color="gray">
          {submission.client.name}
        </Text>
      </Flex>
      <Flex my={1}>
        <Text>E-mail</Text>
        <Link ml={4} href={`mailto:${submission.client.email}`} color="primary">
          {submission.client.email}
        </Link>
      </Flex>
    </>
  );
};
