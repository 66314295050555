import React from "react";
import { useParams } from "react-router-dom";
import { PageLayout } from "../../components/PageLayout";
import { SolutionDetailsContainer } from "../../components/SolutionDetails";
import { SolutionDetails } from "../../components/SolutionDetails/SolutionDetails";
import { SubmissionDetails } from "../../components/SubmissionDetails";

interface Props {}

export const SolutionDetailsPage: React.FC<Props> = () => {
  const { solutionSlug } = useParams();
  return (
    <PageLayout>
      <SolutionDetailsContainer solutionSlug={solutionSlug!} />
    </PageLayout>
  );
};

SolutionDetailsPage.defaultProps = {};
