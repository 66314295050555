import React from "react";
import { SolutionDetails } from "./SolutionDetails";
import { LoadingSpinner } from "../LoadingSpinner";
import { Solution } from "../../types/Solution";
import { useSolution } from "../../queryHooks/useSolution";

interface Props {
  solutionSlug: Solution["slug"];
}

export const SolutionDetailsContainer: React.FC<Props> = ({ solutionSlug }) => {
  const { isLoading, data: solution } = useSolution(solutionSlug);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return <>{solution ? <SolutionDetails solution={solution} /> : null}</>;
};

SolutionDetailsContainer.defaultProps = {};
