import { Flex, Icon, Link, SimpleGrid, Text } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { useDropzone, Accept } from "react-dropzone";
import { BsCloudUpload } from "react-icons/bs";
import { Submission } from "../../types/SubmissionType";
import {
  acceptStyle,
  baseStyle,
  focusedStyle,
  rejectStyle,
} from "../../utils/dropzoneStyles";
import { SingleFileUpload } from "./_/SingleFileUpload";

interface Props {
  submissionId: Submission["id"];
  maxFiles?: number;
  maxFileSize?: number;
  acceptedMimeType?: Accept;
}

export const SubmissionFileUpload: React.FC<Props> = ({
  submissionId,
  maxFiles,
  maxFileSize,
  acceptedMimeType,
}) => {
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    acceptedFiles,
  } = useDropzone({
    maxFiles: maxFiles,
    maxSize: maxFileSize,
    accept: acceptedMimeType,
  });

  /* istanbul ignore next */
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject],
  );

  return (
    <>
      <Text variant="accent">Upload additional files</Text>
      <Flex data-testid="dropzone" sx={style} {...getRootProps()} my={4}>
        <input id="uploadFile" {...getInputProps()} />
        <Icon as={BsCloudUpload} h={20} w={12} color="blue.600" />
        <Text color="grey.900">
          Drop file here, or <Link color="blue.600">browse for it</Link>
        </Text>
      </Flex>
      <SimpleGrid
        columns={[1, 2, 2, 2, 3, 4]}
        spacing={5}
        my={5}
        p={2}
        templateRows="auto"
      >
        {acceptedFiles.map((file: File) => (
          <SingleFileUpload
            submissionId={submissionId}
            file={file}
            key={file.name}
          />
        ))}
      </SimpleGrid>
    </>
  );
};

SubmissionFileUpload.defaultProps = {
  maxFiles: 5,
  maxFileSize: 5000000, //5mb in bytes;
  acceptedMimeType: {
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      [],
    "application/pdf": [],
  },
};
