import {
  AspectRatio,
  Box,
  Button,
  Flex,
  Heading,
  Link,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  UnorderedList,
  useClipboard,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { Paper } from "../Paper";
import { SolutionBasicDetails } from "./_/SolutionBasicDetails/SolutionBasicDetails";
import { Solution } from "../../types/Solution";
import { SolutionFiles } from "../SolutionFiles";
import { LoadingSpinner } from "../LoadingSpinner";
import { getSolutionLandingPageContent } from "../../services/SolutionService";
import { SolutionPaymentContainer } from "./_/SolutionPaymentDetails/SolutionPaymentContainer";

interface Props {
  solution: Solution;
}

export const SolutionDetails: React.FC<Props> = ({ solution }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [generatingContent, setGeneratingContent] = useState(false);
  const [landingContent, setLandingContent] = useState("");
  const { setValue, onCopy, hasCopied } = useClipboard("");

  function generateLandingContent() {
    setGeneratingContent(true);

    getSolutionLandingPageContent(solution.slug).then((data) => {
      setLandingContent(data);
      setValue(data);
      setGeneratingContent(false);
      onOpen();
    });
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Landing Page Content</ModalHeader>
          <ModalCloseButton />
          <ModalBody maxH={"300px"} overflowY={"auto"}>
            <Link
              mb={4}
              target="_blank"
              color="primary"
              href="https://firmsy-asset-public-storage.s3.ap-southeast-2.amazonaws.com/landing_page.png"
            >
              Landing Page Example
            </Link>
            <Text whiteSpace={"pre-line"}>{landingContent}</Text>
          </ModalBody>
          <ModalFooter>
            <Button variant="secondary" mr={3} onClick={onCopy}>
              {hasCopied ? "Copied!" : "Copy"}
            </Button>
            <Button variant="primary" onClick={onClose}>
              Done
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Flex justifyContent="space-between" alignItems="center">
        <Heading as="h1" size="xl">
          {solution.name}
        </Heading>
        <Flex>
          {generatingContent ? (
            <Flex mr={6} justifyContent={"center"} flexDirection={"column"}>
              <LoadingSpinner></LoadingSpinner>
            </Flex>
          ) : (
            <Button variant="secondary" mr={3} onClick={generateLandingContent}>
              Generate Landing Page
            </Button>
          )}

          <Button
            variant="primary"
            as="a"
            href={`${process.env.REACT_APP_CLIENT_BASE_URL}/solutions/${solution.slug}`}
            target="_blank"
          >
            View Solution
          </Button>
        </Flex>
      </Flex>
      <Flex justifyContent={"space-between"}>
        <Box w="49%" mb={"-7"}>
          <AspectRatio>
            <iframe
              src={"https://player.vimeo.com/video/820391195?h=5d24b91f37"}
              title="Updating Solution Templates"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </AspectRatio>
        </Box>
        <Box w="49%" pt="6">
          <Text as="i" my={2}>
            Tips:
          </Text>
          <UnorderedList mb={2}>
            <ListItem>
              On this page download, delete, and upload the document templates
              used by the solution to generate final documents
            </ListItem>
            <ListItem>
              Upload files with the exact same name to overwrite existing files
            </ListItem>
            <ListItem>
              Test out your changes by submitting a solution and viewing the
              output documents
            </ListItem>
            <ListItem>
              Click &quot;Reset to Original&quot; to revert the templates to
              their state prior to your changes
            </ListItem>
            <ListItem>
              Edit the text content of the solution interview by clicking
              &quot;View Solution&quot;
            </ListItem>
          </UnorderedList>
        </Box>
      </Flex>
      <SimpleGrid
        columns={[1, 1, 2]}
        spacing={5}
        my={5}
        p={2}
        templateRows="auto"
      >
        <Paper>
          <SolutionBasicDetails solution={solution} />
          <SolutionPaymentContainer solutionSlug={solution.slug} />
        </Paper>
        <Paper>{<SolutionFiles solutionSlug={solution.slug} />}</Paper>
      </SimpleGrid>
    </>
  );
};

SolutionDetails.defaultProps = {};
