import { Box, Flex, Heading, SimpleGrid } from "@chakra-ui/react";
import React from "react";
import { Submission } from "../../types/SubmissionType";
import { Paper } from "../Paper";
import { SubmissionBasicDetails } from "./_/SubmissionBasicDetails";
import { SubmissionNotes } from "./_/SubmissionNotes";
import { SubmissionFileUpload } from "../SubmissionFileUpload";
import { CloseSubmission } from "../CloseSubmission";
import { ViewSubmission } from "../ViewSubmission";
import { SubmissionFiles } from "../SubmissionFiles";
import { AttachmentFiles } from "../AttachmentFiles";

interface Props {
  submission: Submission;
}

export const SubmissionDetails: React.FC<Props> = ({ submission }) => {
  return (
    <>
      <Flex justifyContent="space-between" alignItems="center">
        <Heading
          as="h1"
          size="xl"
        >{`${submission.client.name} - ${submission.solution.name}`}</Heading>
        <Flex>
          <Box mr={4}>
            <CloseSubmission submission={submission} />
          </Box>
          <ViewSubmission submission={submission} />
        </Flex>
      </Flex>
      <SimpleGrid
        columns={[1, 1, 2]}
        spacing={5}
        my={5}
        p={2}
        templateRows="auto"
      >
        <Paper>
          <SubmissionBasicDetails submission={submission} />
        </Paper>
        <Paper>
          <SubmissionNotes submissionId={submission.id} />
        </Paper>
        <Paper>
          <SubmissionFiles submissionId={submission.id} />
        </Paper>
        <Paper>
          <SubmissionFileUpload submissionId={submission.id} />
        </Paper>
        <Paper>
          <AttachmentFiles submissionId={submission.id} />
        </Paper>
      </SimpleGrid>
    </>
  );
};

SubmissionDetails.defaultProps = {};
