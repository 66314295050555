import { isEmpty } from "lodash";
import { SubmissionFile } from "../types/SubmissionFile";

export class SubmissionFileUtils {
  static getLastModifiedFileDate(files: SubmissionFile[]): Date | undefined {
    const lastModifiedFile = !isEmpty(files)
      ? files.reduce((a, b) => {
          return a.lastModified > b.lastModified ? a : b;
        })
      : { lastModified: undefined };

    return lastModifiedFile.lastModified;
  }
}
