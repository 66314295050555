import { Button, Flex, Text, Textarea } from "@chakra-ui/react";
import React, { ChangeEvent, useEffect, useState } from "react";
import { SubmissionNote } from "../../../../types/SubmissionNote";
import { DateFormat } from "../../../../utils/DateFormat";

interface Props {
  value: SubmissionNote;
  onSave: (value: string) => void;
  isSaving: boolean;
}

export const SubmissionNotes: React.FC<Props> = ({
  value,
  onSave,
  isSaving,
}) => {
  const [val, setValue] = useState(value.note);

  useEffect(() => setValue(value.note), [value.note]);

  const handleOnChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event?.target.value!);
  };

  const handleOnSave = () => {
    onSave(val!);
  };

  return (
    <>
      <Text variant="accent">Notes</Text>
      <Flex mb={1}>
        <Text fontSize={13} color="gray">
          Last Saved:
        </Text>
        <Text fontSize={13} color="gray" ml={2}>
          {DateFormat.formatToLocaleString(value.updatedAt)}
        </Text>
      </Flex>
      <Textarea
        height="100%"
        onChange={handleOnChange}
        value={val}
        placeholder="add some notes"
        maxLength={5000}
      />
      <Button
        width="25%"
        mt={3}
        height="80px"
        variant="primary"
        alignSelf="flex-end"
        onClick={handleOnSave}
        isLoading={isSaving}
      >
        Save
      </Button>
    </>
  );
};

SubmissionNotes.defaultProps = {
  value: {
    id: -1,
    note: "",
  },
};
