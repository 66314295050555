import React from "react";
import { PageLayout } from "../../components/PageLayout";
import { SolutionList } from "../../components/SolutionList";

interface Props {}

export const SolutionListPage: React.FC<Props> = () => {
  return (
    <PageLayout>
      <SolutionList />
    </PageLayout>
  );
};
