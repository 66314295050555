import { Badge } from "@chakra-ui/react";
import React from "react";
import { Submission } from "../../types/SubmissionType";

interface Props {
  submissionStatus: Submission["status"];
}

const variants = {
  OPEN: undefined,
  IN_PROGRESS: "red",
  COMPLETED: "green",
};

const words = {
  OPEN: "Open",
  IN_PROGRESS: "In progress",
  COMPLETED: "Completed",
};

export const SubmissionStatusBadge: React.FC<Props> = ({
  submissionStatus,
}) => {
  return (
    <Badge data-testid={submissionStatus} variant={variants[submissionStatus]}>
      {words[submissionStatus]}
    </Badge>
  );
};
