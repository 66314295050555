import React from "react";
import { useSubmissionAttachments } from "../../queryHooks/useSubmissionAttachments";
import { SubmissionFile } from "../../types/SubmissionFile";
import { Submission } from "../../types/SubmissionType";
import { LoadingSpinner } from "../LoadingSpinner";
import { AttachmentFiles } from "./AttachmentFiles";

interface Props {
  submissionId: Submission["id"];
}

export const AttachmentFilesContainer: React.FC<Props> = ({ submissionId }) => {
  const { isLoading, data: files } = useSubmissionAttachments(submissionId);

  const handleOnClick = (file: SubmissionFile) => {
    window.open(file.preSignedUrl, "_blank");
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return <AttachmentFiles attachments={files!} onClick={handleOnClick} />;
};
