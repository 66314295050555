import { Button } from "@chakra-ui/react";
import React from "react";

export interface ResetSolutionFilesProps {
  onResetSolutionFiles: () => void;
  isLoading?: boolean;
  isDisabled?: boolean;
}

export const ResetSolutionFiles: React.FC<ResetSolutionFilesProps> = ({
  onResetSolutionFiles,
  isLoading,
}) => {
  return (
    <Button
      variant="primary"
      alignSelf="flex-end"
      disabled={isLoading}
      isLoading={isLoading}
      onClick={onResetSolutionFiles}
      mt={5}
    >
      Reset to Original
    </Button>
  );
};

ResetSolutionFiles.defaultProps = {
  isLoading: false,
  isDisabled: false,
};
