import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Switch,
  Text,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import {
  bold,
  italic,
  strikethrough,
  hr,
  link,
  code,
  unorderedListCommand,
  orderedListCommand,
} from "@uiw/react-md-editor/lib/commands";
import React, { useState } from "react";
import { Solution } from "../../../../types/Solution";
import { DateFormat } from "../../../../utils/DateFormat";
import { useSaveSolutionDetails } from "../../../../queryHooks/useSolution";
import MDEditor from "@uiw/react-md-editor";
import rehypeSanitize from "rehype-sanitize";

import { Email } from "../../../../types/Email";
import {
  useSaveSolutionEmails,
  useSolutionEmails,
} from "../../../../queryHooks/useSolutionEmailsContent";

interface Props {
  solution: Solution;
}

export const SolutionBasicDetails: React.FC<Props> = ({ solution }) => {
  const mutation = useSaveSolutionDetails(solution.slug);
  const emailMutation = useSaveSolutionEmails(solution.slug);
  const { isLoading, data, refetch } = useSolutionEmails(solution.slug);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [emailChanged, setEmailChanged] = useState(false);
  const [currentMailable, setCurrentMailable] = useState<Email>({
    mailable: "",
    subject: "",
    bodyContent: "",
    footerContent: "",
  });

  const [requiresReview, setRequiresReview] = useState(solution.requiresReview);
  const [submitMessage, setSubmitMessage] = useState(solution.submitMessage);

  const updateSolutionDetails = () => {
    mutation.mutate({ requiresReview, submitMessage });
  };

  const updateCurrentMailable = (mailable: string) => {
    const currentEmail = data.emails.find(
      (e: Email) => e.mailable === mailable,
    );
    setCurrentMailable({
      mailable: mailable,
      subject: currentEmail ? currentEmail.subject : "",
      bodyContent: currentEmail ? currentEmail.bodyContent : "",
      footerContent: currentEmail ? currentEmail.footerContent : "",
    });
    setEmailChanged(false);
  };

  const openEmailManager = () => {
    refetch().then(() => {
      updateCurrentMailable(data.mailables[0].mailable);
    });
    onOpen();
  };

  const saveEmailContent = () => {
    emailMutation.mutate(currentMailable);
    setEmailChanged(false);
    onClose();
  };

  return (
    <>
      <Text variant="accent" mb={0}>
        Solution Details
      </Text>
      <Text fontSize={13} color="gray" mb={3}>
        V: {solution.version}
      </Text>
      <Flex my={2}>
        <Text>Published Date</Text>
        <Text ml={4} color="gray">
          {DateFormat.format(solution.publishedAt)}
        </Text>
      </Flex>
      <Flex my={2}>
        <Text>URL</Text>
        <Text ml={4} color="gray">
          <Link
            variant="primary"
            as="a"
            href={`${process.env.REACT_APP_CLIENT_BASE_URL}/solutions/${solution.slug}`}
            target="_blank"
          >
            {process.env.REACT_APP_CLIENT_BASE_URL}/solutions/{solution.slug}
          </Link>
        </Text>
      </Flex>
      <Box my={2}>
        <FormControl display="flex" alignItems="center">
          <FormLabel htmlFor="requires-review" mb="0">
            Requires Review
          </FormLabel>
          <Switch
            id="requires-review"
            defaultChecked={solution.requiresReview}
            onChange={(e) => {
              setRequiresReview((e.target as HTMLInputElement).checked);
              updateSolutionDetails();
            }}
          />
        </FormControl>
        <Text fontSize={13} color="gray" mb={3}>
          If Requires Review is off, the documents will be sent to the client
          immediately after they submit
        </Text>
        <FormLabel htmlFor="submit-message" mb="0" mt="3">
          Submit Message
        </FormLabel>
        <Text fontSize={13} color="gray" mb={3}>
          This message will show to the user after they submit this solution
        </Text>
        <FormControl display="flex" alignItems="center">
          <MDEditor
            value={submitMessage}
            onChange={(v) => {
              setSubmitMessage(v || "");
            }}
            onBlur={updateSolutionDetails}
            data-color-mode="light"
            textareaProps={{
              placeholder:
                "Your submission is being reviewed by our team. You will receive it by email within the next 3 business days.",
            }}
            commands={[
              bold,
              italic,
              strikethrough,
              hr,
              link,
              code,
              unorderedListCommand,
              orderedListCommand,
            ]}
            preview="edit"
            previewOptions={{
              rehypePlugins: [[rehypeSanitize]],
            }}
            style={{
              width: "100%",
            }}
            id="submit-message"
          />
        </FormControl>
        <Button mt={4} variant="primary" onClick={openEmailManager}>
          Manage Emails
        </Button>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Manage Email Content</ModalHeader>
          <ModalCloseButton />
          <ModalBody maxH={"700px"} overflowY={"auto"}>
            {!data || isLoading ? (
              <Text>Loading</Text>
            ) : (
              <>
                <FormLabel htmlFor="email-select">Select Email</FormLabel>
                <Select
                  id="email-select"
                  isDisabled={emailChanged}
                  onChange={(e) => updateCurrentMailable(e.target.value)}
                >
                  {data.mailables.map(function (mailableData: any) {
                    const readableName = mailableData.mailable
                      .split(/([A-Z][a-z]+)/)
                      .filter(function (e: any) {
                        return e;
                      });
                    return (
                      <option
                        key={mailableData.mailable}
                        value={mailableData.mailable}
                      >
                        {readableName[0]} {readableName[1]}
                      </option>
                    );
                  })}
                </Select>
                <FormControl mt={4}>
                  <FormLabel htmlFor="email-subject">Subject</FormLabel>
                  <Input
                    key={`subject-${currentMailable.mailable}`}
                    defaultValue={currentMailable.subject}
                    placeholder={
                      currentMailable.mailable
                        ? data.mailables.find(
                            (m: any) => m.mailable === currentMailable.mailable,
                          ).subject
                        : ""
                    }
                    id="email-subject"
                    onChange={(e) => {
                      setCurrentMailable({
                        ...currentMailable,
                        subject: e.target.value,
                      });
                      setEmailChanged(true);
                    }}
                  />
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel htmlFor="email-body">Email Body</FormLabel>
                  <Textarea
                    key={`body-${currentMailable.mailable}`}
                    defaultValue={currentMailable.bodyContent}
                    placeholder={
                      currentMailable.mailable
                        ? data.mailables.find(
                            (m: any) => m.mailable === currentMailable.mailable,
                          ).bodyContent
                        : ""
                    }
                    id="email-body"
                    onChange={(e) => {
                      setCurrentMailable({
                        ...currentMailable,
                        bodyContent: e.target.value,
                      });
                      setEmailChanged(true);
                    }}
                  />
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel htmlFor="email-footer">Email Footer</FormLabel>
                  <Textarea
                    key={`footer-${currentMailable.mailable}`}
                    defaultValue={currentMailable.footerContent}
                    placeholder={
                      currentMailable.mailable
                        ? data.mailables.find(
                            (m: any) => m.mailable === currentMailable.mailable,
                          ).footerContent
                        : ""
                    }
                    id="email-footer"
                    onChange={(e) => {
                      setCurrentMailable({
                        ...currentMailable,
                        footerContent: e.target.value,
                      });
                      setEmailChanged(true);
                    }}
                  />
                </FormControl>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <Button variant="secondary" mr={4} onClick={onClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={saveEmailContent}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
