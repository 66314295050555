import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import {
  getPaymentForSolution,
  saveSolutionPayment,
} from "../services/PaymentService";
import { SolutionPayment } from "../types/Payment";
import { Solution } from "../types/Solution";

export const useSolutionPayment = (solutionSlug: Solution["slug"]) =>
  useQuery(
    [`solution-payment-${solutionSlug}`],
    () => getPaymentForSolution(solutionSlug),
    {
      staleTime: 60000, // expires after 60 secs
    },
  );

/* istanbul ignore next */
export const useSaveSolutionPayment = (solutionSlug: Solution["slug"]) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (data: SolutionPayment) => saveSolutionPayment(solutionSlug, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [`solution-payment-${solutionSlug}`],
        });
      },
    },
  );
  return mutation;
};
