import { isEmpty } from "lodash";
import React, { useState } from "react";
import {
  useSubmissionFileDelete,
  useSubmissionFiles,
} from "../../queryHooks/useSubmissionFiles";
import { getSubmissionFile } from "../../services/SubmissionsService";
import { SubmissionFile } from "../../types/SubmissionFile";
import { Submission } from "../../types/SubmissionType";
import { ApproveSubmission } from "../ApproveSubmission";
import { ConfirmationDialog } from "../ConfirmationDialog";
import { LoadingSpinner } from "../LoadingSpinner";
import { SubmissionFiles } from "./SubmissionFiles";

export interface SubmissionFilesProps {
  submissionId: Submission["id"];
  getFileUrl?: (
    submissionId: Submission["id"],
    filename: string,
  ) => Promise<SubmissionFile>;
}

export const SubmissionFilesContainer: React.FC<SubmissionFilesProps> = ({
  submissionId,
  getFileUrl,
}) => {
  const { isLoading, data: files } = useSubmissionFiles(submissionId);

  // File deletion
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState<string>("");

  const deleteFileMutation = useSubmissionFileDelete(submissionId);

  const handleOnDelete = (filename: string) => {
    setIsModalOpen(true);
    setFileToDelete(filename);
  };

  const handleOnCancelDelete = () => {
    setIsModalOpen(false);
    setFileToDelete("");
  };

  const handleOnConfirmDelete = () => {
    deleteFileMutation.mutate(fileToDelete);
    setIsModalOpen(false);
    setFileToDelete("");
  };

  // File download
  const handleOnDownload = async (filename: string) => {
    const submissionFile = await getFileUrl!(submissionId, filename);
    window.open(submissionFile.preSignedUrl, "_blank");
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <SubmissionFiles
        files={files!}
        onDownload={handleOnDownload}
        onDelete={handleOnDelete}
      />
      <ApproveSubmission
        submissionId={submissionId}
        isDisabled={isEmpty(files)}
      />
      <ConfirmationDialog
        title="Delete document"
        content={`Are you sure you want to delete "${fileToDelete}"?`}
        onCancel={handleOnCancelDelete}
        onConfirm={handleOnConfirmDelete}
        isOpen={isModalOpen}
      />
    </>
  );
};

SubmissionFilesContainer.defaultProps = {
  getFileUrl: getSubmissionFile,
};
