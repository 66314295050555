import ApiClient from "../apiClient";
import { Firm } from "../types/Firm";

export const getBrandingForFirm = async (): Promise<Firm> => {
  return (await ApiClient.getInstance().get("/firm-app/branding")).data;
};

export const saveFirmBranding = async (data: Firm): Promise<any> => {
  return await ApiClient.getInstance().put(`/firm-app/branding`, data);
};

export const uploadBrandingLogo = async (file: File): Promise<void> => {
  const formData = new FormData();
  formData.append("file", file);
  await ApiClient.getInstance().post(`/firm-app/branding/logo`, formData);
};
