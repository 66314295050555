import { isEmpty } from "lodash";
import React, { useState } from "react";
import {
  useSolutionFileDelete,
  useSolutionFiles,
} from "../../queryHooks/useSolutionFiles";
import { getSolutionFile } from "../../services/SolutionService";
import { Solution } from "../../types/Solution";
import { SubmissionFile } from "../../types/SubmissionFile";
import { ConfirmationDialog } from "../ConfirmationDialog";
import { LoadingSpinner } from "../LoadingSpinner";
import { ResetSolutionFiles } from "../ResetSolutionFiles";
import { SolutionFileUpload } from "../SolutionFileUpload";
import { SolutionFiles } from "./SolutionFiles";

export interface SolutionFilesProps {
  solutionSlug: Solution["slug"];
  getFileUrl?: (
    solutionSlug: Solution["slug"],
    filename: string,
  ) => Promise<SubmissionFile>;
}

export const SolutionFilesContainer: React.FC<SolutionFilesProps> = ({
  solutionSlug,
  getFileUrl,
}) => {
  const { isLoading, data: files } = useSolutionFiles(solutionSlug);

  // File deletion
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState<string>("");

  const deleteFileMutation = useSolutionFileDelete(solutionSlug);

  const handleOnDelete = (filename: string) => {
    setIsModalOpen(true);
    setFileToDelete(filename);
  };

  const handleOnCancelDelete = () => {
    setIsModalOpen(false);
    setFileToDelete("");
  };

  const handleOnConfirmDelete = () => {
    deleteFileMutation.mutate(fileToDelete);
    setIsModalOpen(false);
    setFileToDelete("");
  };

  // File download
  const handleOnDownload = async (filename: string) => {
    const solutionTemplate = await getFileUrl!(solutionSlug, filename);
    window.open(solutionTemplate.preSignedUrl, "_blank");
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <SolutionFiles
        files={files!}
        onDownload={handleOnDownload}
        onDelete={handleOnDelete}
      />
      <ResetSolutionFiles solutionSlug={solutionSlug} />
      <ConfirmationDialog
        title="Delete document"
        content={`Are you sure you want to delete "${fileToDelete}"?`}
        onCancel={handleOnCancelDelete}
        onConfirm={handleOnConfirmDelete}
        isOpen={isModalOpen}
      />
      <SolutionFileUpload solutionSlug={solutionSlug} />
    </>
  );
};

SolutionFilesContainer.defaultProps = {
  getFileUrl: getSolutionFile,
};
