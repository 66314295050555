import { Flex } from "@chakra-ui/react";
import React from "react";
import { PageLayout } from "../../components/PageLayout";
import { Sidebar } from "../../components/Sidebar";
import { SubmissionList } from "../../components/SubmissionList";

interface Props {}

export const SubmissionListPage: React.FC<Props> = () => {
  return (
    <PageLayout>
      <SubmissionList />
    </PageLayout>
  );
};
