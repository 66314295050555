import { Flex, FlexProps } from "@chakra-ui/react";
import React from "react";
import { Sidebar } from "../Sidebar";

interface Props {
  children: React.ReactNode;
}

export const PageLayout: React.FC<Props> = ({ children }) => {
  return (
    <Flex>
      <Sidebar />
      <Flex
        w="100vw"
        h="100vh"
        p="2%"
        flexDir="column"
        data-testid="layout"
        overflowY="auto"
      >
        {children}
      </Flex>
    </Flex>
  );
};
