export const baseStyle: React.CSSProperties = {
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: "md",
  borderColor: "grey.400",
  borderStyle: "dashed",
  backgroundColor: "blue.300",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
  cursor: "pointer",
};

export const focusedStyle: React.CSSProperties = {
  borderColor: "blue.500",
  borderStyle: "dashed",
  borderRadius: "md",
  borderWidth: 2,
};

export const acceptStyle: React.CSSProperties = {
  borderColor: "teal.500",
  borderStyle: "dashed",
  borderRadius: "md",
  borderWidth: 2,
};

export const rejectStyle: React.CSSProperties = {
  borderColor: "pink.600",
  // backgroundColor: "pink.50",
  borderStyle: "dashed",
  borderRadius: "md",
  borderWidth: 2,
};
