import { Alert, AlertIcon, useDisclosure, useToast } from "@chakra-ui/react";
import React, { useState } from "react";
import { useSolutionFilesReset } from "../../queryHooks/useSolutionFiles";
import { Solution } from "../../types/Solution";
import { ConfirmationDialog } from "../ConfirmationDialog";
import {
  ResetSolutionFiles,
  ResetSolutionFilesProps,
} from "./ResetSolutionFiles";

interface Props {
  solutionSlug: Solution["slug"];
  isDisabled?: ResetSolutionFilesProps["isDisabled"];
}

export const ResetSolutionFilesContainer: React.FC<Props> = ({
  solutionSlug,
}) => {
  const resetSolutionFiles = useSolutionFilesReset(solutionSlug);
  const [isResettingSolutionFiles, setIsResettingSolutionFiles] =
    useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toast = useToast();

  const handleOnResetSolutionFiles = () => {
    setIsModalOpen(true);
  };

  const handleOnCancel = () => {
    setIsModalOpen(false);
  };

  const handleOnConfirm = async () => {
    setIsModalOpen(false);
    setIsResettingSolutionFiles(true);

    await resetSolutionFiles.mutate(solutionSlug);

    toast({
      title: "",
      description:
        "Your files for this solution have been reset to their original state",
      status: "success",
      duration: 5000,
      isClosable: true,
      position: "bottom-right",
    });

    setIsResettingSolutionFiles(false);
  };

  return (
    <>
      <ResetSolutionFiles
        onResetSolutionFiles={handleOnResetSolutionFiles}
        isLoading={isResettingSolutionFiles}
      />
      <ConfirmationDialog
        title="Reset File Templates"
        content="Are you sure you want to reset these files to their first original state? Make sure you create a local backup of your own files so your changes aren't lost."
        onCancel={handleOnCancel}
        onConfirm={handleOnConfirm}
        isOpen={isModalOpen}
      />
    </>
  );
};

ResetSolutionFilesContainer.defaultProps = {
  isDisabled: false,
};
