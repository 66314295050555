import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getEmailsForSolution,
  saveSolutionEmail,
} from "../services/EmailService";
import { Email } from "../types/Email";
import { Solution } from "../types/Solution";

export const useSolutionEmails = (solutionSlug: Solution["slug"]) =>
  useQuery(
    [`solution-emails-${solutionSlug}`],
    () => getEmailsForSolution(solutionSlug),
    {
      staleTime: 60000, // expires after 60 secs
    },
  );

/* istanbul ignore next */
export const useSaveSolutionEmails = (solutionSlug: Solution["slug"]) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (data: Email) => saveSolutionEmail(solutionSlug, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [`solution-payment-${solutionSlug}`],
        });
      },
    },
  );
  return mutation;
};
