import { Center, Flex, Text } from "@chakra-ui/react";
import { isEmpty } from "lodash";
import React from "react";
import { SubmissionFile } from "../../types/SubmissionFile";
import { DateFormat } from "../../utils/DateFormat";
import { SubmissionFileUtils } from "../../utils/SubmissionFileUtils";
import { FileRow } from "../FileRow";
import { FileRowProps } from "../FileRow/FileRow";

interface Props {
  files: SubmissionFile[];
  onDownload: FileRowProps["onDownload"];
  onDelete: FileRowProps["onDelete"];
}

export const SubmissionFiles: React.FC<Props> = ({
  files,
  onDownload,
  onDelete,
}) => {
  return (
    <>
      <Text variant="accent">Submission files</Text>
      <Flex mb={1}>
        <Text fontSize={13} color="gray">
          Last edited:
        </Text>
        <Text fontSize={13} color="gray" ml={2}>
          {DateFormat.formatToLocaleString(
            SubmissionFileUtils.getLastModifiedFileDate(files),
          )}
        </Text>
      </Flex>
      {!isEmpty(files) ? (
        <Flex flexDirection="column" my={3}>
          {files.map((file) => (
            <FileRow
              filename={file.filename}
              onDownload={onDownload}
              onDelete={onDelete}
              key={file.filename}
            />
          ))}
        </Flex>
      ) : (
        <Center>
          <Text>No files found</Text>
        </Center>
      )}
    </>
  );
};

SubmissionFiles.defaultProps = {};
