import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getBrandingForFirm,
  saveFirmBranding,
  uploadBrandingLogo,
} from "../services/BrandingService";
import { Firm } from "../types/Firm";

const key = () => ["firm", "branding"];

export const useFirmBranding = () => useQuery(key(), getBrandingForFirm);

/* istanbul ignore next */
export const useSaveFirmBranding = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation((data: Firm) => saveFirmBranding(data), {
    onSuccess: (res) => {
      console.log(res);
      queryClient.invalidateQueries({
        queryKey: key(),
      });
    },
  });
  return mutation;
};

/* istanbul ignore next */
export const useLogoFileUpload = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation((newFile: File) => uploadBrandingLogo(newFile), {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: key(),
      });
    },
  });
  return mutation;
};
