import { Center, Spinner } from "@chakra-ui/react";
import React from "react";

interface Props {}

export const LoadingSpinner: React.FC<Props> = () => {
  return (
    <Center>
      <Spinner data-testid="loading" />
    </Center>
  );
};

LoadingSpinner.defaultProps = {};
