import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  deleteSolutionfile,
  getSolutionFiles,
  resetSolutionFiles,
  uploadSolutionFile,
} from "../services/SolutionService";
import { Solution } from "../types/Solution";

const key = (solutionSlug: Solution["slug"]) => [
  "solution",
  "files",
  solutionSlug,
];

export const useSolutionFiles = (solutionSlug: Solution["slug"]) =>
  useQuery(key(solutionSlug), () => getSolutionFiles(solutionSlug), {
    staleTime: 600000, //expires after 10mins
  });

/* istanbul ignore next */
export const useSolutionFileUpload = (solutionSlug: Solution["slug"]) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (newFile: File) => uploadSolutionFile(solutionSlug, newFile),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: key(solutionSlug),
          refetchType: "active",
        });
      },
    },
  );
  return mutation;
};

/* istanbul ignore next */
export const useSolutionFileDelete = (solutionSlug: Solution["slug"]) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (filename: string) => deleteSolutionfile(solutionSlug, filename),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: key(solutionSlug),
          refetchType: "active",
        });
      },
    },
  );
  return mutation;
};

/* istanbul ignore next */
export const useSolutionFilesReset = (solutionSlug: Solution["slug"]) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (filename: string) => resetSolutionFiles(solutionSlug),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: key(solutionSlug),
          refetchType: "active",
        });
      },
    },
  );
  return mutation;
};
