import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getSolutionBySlug,
  saveSolutionDetails,
} from "../services/SolutionService";
import { Solution } from "../types/Solution";

const key = (solutionSlug: string) => ["solution", solutionSlug];

export const useSolution = (solutionSlug: Solution["slug"]) =>
  useQuery(key(solutionSlug), () => getSolutionBySlug(solutionSlug), {
    staleTime: 60000, // expires after 60 secs
  });

/* istanbul ignore next */
export const useSaveSolutionDetails = (solutionSlug: Solution["slug"]) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (data: { requiresReview: boolean; submitMessage: string }) =>
      saveSolutionDetails(
        solutionSlug,
        data.requiresReview,
        data.submitMessage,
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: key(solutionSlug),
        });
      },
    },
  );
  return mutation;
};
