import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  closeSubmission,
  getSubmissionById,
} from "../services/SubmissionsService";

const key = (submissionId: number) => ["submission", submissionId];

export const useSubmission = (submissionId: number) =>
  useQuery(key(submissionId), () => getSubmissionById(submissionId), {
    staleTime: 60000, // expires after 60 secs
  });

/* istanbul ignore next */
export const useCloseSubmission = (submissionId: number) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(() => closeSubmission(submissionId), {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: key(submissionId),
        refetchType: "active",
      });
    },
  });
  return mutation;
};
