import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import ApiClient from "./apiClient";
import { ProtectedRoute } from "./components/ProtectedRoute";
import { CompanySettingsPage } from "./pages/CompanySettingsPage";
import { HomePage } from "./pages/HomePage";
import { SolutionDetailsPage } from "./pages/SolutionDetailsPage";
import { SolutionListPage } from "./pages/SolutionListPage";
import { SubmissionDetailsPage } from "./pages/SubmissionDetailsPage";
import { SubmissionListPage } from "./pages/SubmissionListPage";

function App() {
  const { getAccessTokenSilently } = useAuth0();
  useEffect(() => {
    ApiClient.getInstance().withTokenGenerator(getAccessTokenSilently);
  }, [getAccessTokenSilently]);
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <HomePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/submissions"
          element={
            <ProtectedRoute>
              <SubmissionListPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/submissions/:submissionId"
          element={
            <ProtectedRoute>
              <SubmissionDetailsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/solutions"
          element={
            <ProtectedRoute>
              <SolutionListPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/solutions/:solutionSlug"
          element={
            <ProtectedRoute>
              <SolutionDetailsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/company-settings"
          element={
            <ProtectedRoute>
              <CompanySettingsPage />
            </ProtectedRoute>
          }
        />
      </Routes>
    </>
  );
}

export default App;
