import ApiClient from "../apiClient";
import { Solution, SolutionBasicInformation } from "../types/Solution";
import { SolutionFile } from "../types/SolutionFile";

export const getSolutionsForFirm = async (): Promise<
  SolutionBasicInformation[]
> => {
  return (await ApiClient.getInstance().get("/firm-app/solutions")).data;
};

export const getSolutionBySlug = async (
  solutionSlug: Solution["slug"],
): Promise<Solution> => {
  return (
    await ApiClient.getInstance().get(`/firm-app/solutions/${solutionSlug}`)
  ).data;
};

export const saveSolutionDetails = async (
  solutionSlug: Solution["slug"],
  requiresReview: boolean,
  submitMessage: string,
): Promise<void> => {
  await ApiClient.getInstance().put(`/firm-app/solutions/${solutionSlug}`, {
    requiresReview,
    submitMessage,
  });
};

export const resetSolutionFiles = async (
  solutionSlug: Solution["slug"],
): Promise<void> => {
  await ApiClient.getInstance().put(
    `/firm-app/solutions/${solutionSlug}/reset-templates`,
    {},
  );
};

export const getSolutionFiles = async (
  solutionSlug: Solution["slug"],
): Promise<SolutionFile[]> => {
  return (
    await ApiClient.getInstance().get(
      `/firm-app/solutions/${solutionSlug}/templates`,
    )
  ).data;
};

export const getSolutionFile = async (
  solutionSlug: Solution["slug"],
  filename: string,
): Promise<SolutionFile> => {
  return (
    await ApiClient.getInstance().get(
      `/firm-app/solutions/${solutionSlug}/templates/${filename}`,
    )
  ).data;
};

export const getSolutionLandingPageContent = async (
  solutionSlug: Solution["slug"],
): Promise<string> => {
  return (
    await ApiClient.getInstance().get(
      `/firm-app/solutions/${solutionSlug}/landing`,
    )
  ).data;
};

export const uploadSolutionFile = async (
  solutionSlug: Solution["slug"],
  file: File,
): Promise<void> => {
  const formData = new FormData();
  formData.append("file", file);
  await ApiClient.getInstance().post(
    `/firm-app/solutions/${solutionSlug}/templates`,
    formData,
  );
};

export const deleteSolutionfile = async (
  solutionSlug: Solution["slug"],
  filename: string,
): Promise<void> => {
  await ApiClient.getInstance().delete(
    `/firm-app/solutions/${solutionSlug}/templates/${filename}`,
  );
};
