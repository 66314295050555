import { Box, Flex, Icon, Spinner, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Submission } from "../../../../types/SubmissionType";
import { BsCheckCircle, BsXCircle } from "react-icons/bs";
import { useSubmissionFileUpload } from "../../../../queryHooks/useSubmissionFiles";

export interface SingleFileUploadProps {
  submissionId: Submission["id"];
  file: File;
}

export const SingleFileUpload: React.FC<SingleFileUploadProps> = ({
  submissionId,
  file,
}) => {
  const mutation = useSubmissionFileUpload(submissionId);

  useEffect(() => {
    mutation.mutate(file);
    // eslint-disable-next-line
  }, []);

  return (
    <Flex
      key={file.name}
      bg="grey.300"
      borderRadius="md"
      border="1px solid"
      borderColor="grey.600"
      flexDirection="column"
      justifyContent="space-evenly"
      alignItems="center"
      p={2}
    >
      <Box>
        {mutation.isLoading && (
          <Spinner data-testid="loading" color="primary" />
        )}
        {mutation.isSuccess && (
          <Icon
            as={BsCheckCircle}
            h={6}
            w={12}
            color="success"
            data-testid="success"
          />
        )}
        {mutation.isError && (
          <Icon as={BsXCircle} h={6} w={12} color="error" data-testid="error" />
        )}
      </Box>
      <Text
        textAlign="center"
        verticalAlign="center"
        mx={1}
        color="primary"
        fontStyle="bold"
        fontSize={14}
      >
        {file.name}
      </Text>
    </Flex>
  );
};

SingleFileUpload.defaultProps = {};
