import axios, { AxiosRequestConfig } from "axios";

export default class ApiClient {
  private static instance: ApiClient;

  private tokenGenerator: () => Promise<string | undefined> = () =>
    Promise.resolve(undefined);

  private readonly axiosClient;

  private constructor() {
    this.axiosClient = axios.create({
      baseURL: process.env.REACT_APP_API_BASE_URL,
      timeout: 30000,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    this.axiosClient.interceptors.request.use(
      async (config) => {
        try {
          const token = await this.tokenGenerator();
          if (token) config.headers!["Authorization"] = `Bearer ${token}`;
        } catch (err) {
          console.error(err);
        }

        return config;
      },
      async (error) => Promise.reject(error),
    );
  }

  public static getInstance() {
    if (!ApiClient.instance) {
      ApiClient.instance = new ApiClient();
    }
    return ApiClient.instance;
  }

  withTokenGenerator(generator: () => Promise<string>) {
    this.tokenGenerator = generator;
    return this;
  }

  get(url: string, config?: AxiosRequestConfig) {
    return this.axiosClient.get(url, config);
  }

  post(url: string, data: object, config?: AxiosRequestConfig) {
    return this.axiosClient.post(url, data, config);
  }

  put(url: string, data: object, config?: AxiosRequestConfig) {
    return this.axiosClient.put(url, data, config);
  }

  delete(url: string, config?: AxiosRequestConfig) {
    return this.axiosClient.delete(url, config);
  }
}
