import { Heading } from "@chakra-ui/react";
import React from "react";
import { CompanyBranding } from "../../components/CompanyBranding";
import { PageLayout } from "../../components/PageLayout";

interface Props {}

export const CompanySettingsPage: React.FC<Props> = () => {
  return (
    <PageLayout>
      <Heading as="h3" mb={10}>
        Company Settings
      </Heading>
      <CompanyBranding />
    </PageLayout>
  );
};
