import React, { useState } from "react";
import { approveSubmission as approveSubmissionService } from "../../services/SubmissionsService";
import { Submission } from "../../types/SubmissionType";
import { ConfirmationDialog } from "../ConfirmationDialog";
import { ApproveSubmission, ApproveSubmissionProps } from "./ApproveSubmission";

interface Props {
  submissionId: Submission["id"];
  approveSubmission?: (submissionId: Submission["id"]) => Promise<void>;
  isDisabled?: ApproveSubmissionProps["isDisabled"];
}

export const ApproveSubmissionContainer: React.FC<Props> = ({
  submissionId,
  approveSubmission,
  isDisabled,
}) => {
  const [isApprovingSubmision, setIsApprovingSubmision] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOnApproveSubmission = () => {
    setIsModalOpen(true);
  };

  const handleOnCancel = () => {
    setIsModalOpen(false);
  };

  const handleOnConfirm = async () => {
    setIsModalOpen(false);
    setIsApprovingSubmision(true);
    await approveSubmission!(submissionId);
    setIsApprovingSubmision(false);
  };

  return (
    <>
      <ApproveSubmission
        onApproveSubmission={handleOnApproveSubmission}
        isDisabled={isDisabled}
        isLoading={isApprovingSubmision}
      />
      <ConfirmationDialog
        title="Send documents"
        content="Are you sure you want to send documents?"
        onCancel={handleOnCancel}
        onConfirm={handleOnConfirm}
        isOpen={isModalOpen}
      />
    </>
  );
};

ApproveSubmissionContainer.defaultProps = {
  approveSubmission: approveSubmissionService,
  isDisabled: false,
};
