import {
  Box,
  Button,
  Flex,
  Input,
  Text,
  useControllableState,
  useOutsideClick,
} from "@chakra-ui/react";
import React, { ChangeEvent, useRef, useState } from "react";
import { ChromePicker, ColorResult } from "react-color";

interface Props {
  name: string;
  label?: string;
  value: string;
  onChange: (value: string) => void;
  onBlur: () => void;
}

export const ColorPicker: React.FC<Props> = ({
  name,
  label,
  onChange,
  onBlur,
  value,
}) => {
  const ref = useRef(null);
  const [showPicker, setShowPicker] = useState(false);

  useOutsideClick({
    ref: ref,
    handler: () => setShowPicker(false),
  });

  return (
    <Flex flexDirection="column" gap={1} ref={ref}>
      {showPicker && (
        <Box position={"absolute"} zIndex={9}>
          <ChromePicker
            disableAlpha={true}
            color={value}
            onChange={(result: ColorResult) => onChange(result.hex)}
            onChangeComplete={(result: ColorResult) => onChange(result.hex)}
          />
        </Box>
      )}
      <Button
        bg={value}
        boxSize={28}
        rounded="md"
        onClick={() => setShowPicker(true)}
      >
        <Text
          fontSize={"sm"}
          padding={1}
          border={"1px"}
          textAlign={"center"}
          borderColor={"#DDD"}
          width={28}
          bg="white"
          rounded="sm"
        >
          {value}
        </Text>
      </Button>
      <Text fontSize="xs" alignSelf="center" overflowWrap="break-word">
        {label}
      </Text>
    </Flex>
  );
};

ColorPicker.defaultProps = {};
