import { Flex, Icon, Text } from "@chakra-ui/react";
import { includes } from "lodash";
import React from "react";
import { FaFileWord } from "react-icons/fa";

interface Props {
  filename: string;
  fileUrl: string;
  onClick: () => void;
}

const excludedPreviewFileExtension = ".docx";

export const FilePreview: React.FC<Props> = ({
  filename,
  fileUrl,
  onClick,
}) => {
  return (
    <Flex
      alignItems="center"
      rounded="20"
      border="1px solid #D5D6D6"
      flexDirection="column"
      justifyContent="space-around"
      h={175}
      w={175}
      m={2}
      onClick={onClick}
      _hover={{
        cursor: "pointer",
      }}
    >
      {!includes(filename, excludedPreviewFileExtension) ? (
        <embed title="attachment" src={fileUrl} width="100px" height="100px" />
      ) : (
        <Icon title="attachment" as={FaFileWord} width="70px" height="70px" />
      )}

      <Text
        ml={2}
        color="primary"
        textAlign="center"
        fontSize={12}
        wordBreak="break-word"
      >
        {filename}
      </Text>
    </Flex>
  );
};
