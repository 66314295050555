import { Button } from "@chakra-ui/react";
import React from "react";

export interface ApproveSubmissionProps {
  onApproveSubmission: () => void;
  isLoading?: boolean;
  isDisabled?: boolean;
}

export const ApproveSubmission: React.FC<ApproveSubmissionProps> = ({
  onApproveSubmission,
  isLoading,
  isDisabled,
}) => {
  return (
    <Button
      variant="primary"
      alignSelf="flex-end"
      disabled={isDisabled || isLoading}
      isLoading={isLoading}
      onClick={onApproveSubmission}
      mt={5}
    >
      Send to client
    </Button>
  );
};

ApproveSubmission.defaultProps = {
  isLoading: false,
  isDisabled: false,
};
