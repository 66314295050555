import { Box, Divider, Flex, Icon, Text } from "@chakra-ui/react";
import React from "react";
import { BsDownload } from "react-icons/bs";
import { AiOutlineDelete } from "react-icons/ai";

export interface FileRowProps {
  filename: string;
  onDownload: (filename: string) => void;
  onDelete: (filename: string) => void;
}

const iconsBaseStyle = {
  h: "25px",
  w: "20px",
  color: "grey.700",
  "&:hover": {
    cursor: "pointer",
    color: "black",
  },
};

export const FileRow: React.FC<FileRowProps> = ({
  filename,
  onDownload,
  onDelete,
}) => {
  const handleOnDownloadClick = () => onDownload(filename);
  const handleOnDeleteClick = () => onDelete(filename);

  return (
    <>
      <Flex
        key={filename}
        justifyContent="space-between"
        alignItems="center"
        my={2}
      >
        <Text
          onClick={handleOnDownloadClick}
          color="primary"
          fontStyle="bold"
          fontSize={15}
          _hover={{
            cursor: "pointer",
          }}
        >
          {filename}
        </Text>
        <Flex justifyContent="space-around">
          <Box mx={2}>
            <abbr title="download">
              <Icon
                as={BsDownload}
                onClick={handleOnDownloadClick}
                data-testid={`download-icon-${filename}`}
                sx={iconsBaseStyle}
              />
            </abbr>
          </Box>
          <Box mx={2}>
            <abbr title="delete">
              <Icon
                as={AiOutlineDelete}
                onClick={handleOnDeleteClick}
                data-testid={`delete-icon-${filename}`}
                sx={iconsBaseStyle}
              />
            </abbr>
          </Box>
        </Flex>
      </Flex>
      <Divider />
    </>
  );
};
