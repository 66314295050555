import React from "react";
import { useParams } from "react-router-dom";
import { PageLayout } from "../../components/PageLayout";
import { SubmissionDetails } from "../../components/SubmissionDetails";

interface Props {}

export const SubmissionDetailsPage: React.FC<Props> = () => {
  const { submissionId } = useParams();
  return (
    <PageLayout>
      <SubmissionDetails submissionId={+submissionId!} />
    </PageLayout>
  );
};

SubmissionDetailsPage.defaultProps = {};
