import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { LoadingSpinner } from "../LoadingSpinner";
import { hotjar } from "react-hotjar";

interface Props {
  children: React.ReactNode;
}

export const ProtectedRoute: React.FC<Props> = ({ children }) => {
  const { isAuthenticated, loginWithRedirect, isLoading, user } = useAuth0();

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (!isAuthenticated) {
    loginWithRedirect();
    return null;
  }

  if (isAuthenticated && user) {
    hotjar.identify(user.email!, { authId: user.sub });
  }

  return <>{children}</>;
};

ProtectedRoute.defaultProps = {};
