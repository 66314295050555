export enum CurrencyCode {
  NZD = "NZD",
  USD = "USD",
  AUD = "AUD",
  GBP = "GBP",
}

export enum PaymentLocation {
  PRE = "PRE",
  POST = "POST",
}

export interface SolutionPayment {
  amount: number;
  fee?: number;
  currency?: CurrencyCode;
  enabled: boolean;
  location: PaymentLocation;
  description?: string;
}
