import { Flex, Icon, Link, SimpleGrid, Text } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { useDropzone, Accept } from "react-dropzone";
import { BsCloudUpload } from "react-icons/bs";
import { Solution } from "../../types/Solution";
import {
  acceptStyle,
  baseStyle,
  focusedStyle,
  rejectStyle,
} from "../../utils/dropzoneStyles";
import { SingleFileUpload } from "./_/SingleFileUpload";

interface Props {
  solutionSlug: Solution["slug"];
  maxFiles?: number;
  maxFileSize?: number;
  acceptedMimeType?: Accept;
}

export const SolutionFileUpload: React.FC<Props> = ({
  solutionSlug,
  maxFiles,
  maxFileSize,
  acceptedMimeType,
}) => {
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    acceptedFiles,
  } = useDropzone({
    maxFiles: maxFiles,
    maxSize: maxFileSize,
    accept: acceptedMimeType,
  });

  /* istanbul ignore next */
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject],
  );

  return (
    <>
      <Text fontWeight={"bold"} mt={2}>
        Upload Templates
      </Text>
      <Flex data-testid="dropzone" sx={style} {...getRootProps()} my={4}>
        <input id="uploadFile" {...getInputProps()} />
        <Icon as={BsCloudUpload} h={20} w={12} color="blue.600" />
        <Text color="grey.900" textAlign={"center"}>
          Drop file here, or <Link color="blue.600">browse for it</Link>. <br />
          Upload files with the exact same filename to overwrite existing
          templates.
        </Text>
      </Flex>
      <SimpleGrid
        columns={[1, 2, 2, 2, 3, 4]}
        spacing={5}
        my={5}
        p={2}
        templateRows="auto"
      >
        {acceptedFiles.map((file: File) => (
          <SingleFileUpload
            solutionSlug={solutionSlug}
            file={file}
            key={file.name}
          />
        ))}
      </SimpleGrid>
    </>
  );
};

SolutionFileUpload.defaultProps = {
  maxFiles: 5,
  maxFileSize: 5000000, //5mb in bytes;
  acceptedMimeType: {
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      [],
  },
};
