import React from "react";
import { useFirmBranding } from "../../queryHooks/useFirmBranding";
import { LoadingSpinner } from "../LoadingSpinner";
import { CompanyBranding } from "./CompanyBranding";

interface Props {}

export const CompanyBrandingContainer: React.FC<Props> = () => {
  const { isLoading, data } = useFirmBranding();

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return data ? <CompanyBranding brand={data} /> : null;
};
