import {
  Flex,
  Heading,
  Icon,
  Image,
  Link,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { isEmpty } from "lodash";
import React, { useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { BsImage } from "react-icons/bs";
import { useLogoFileUpload } from "../../../../queryHooks/useFirmBranding";
import {
  acceptStyle,
  baseStyle,
  focusedStyle,
  rejectStyle,
} from "../../../../utils/dropzoneStyles";

interface Props {
  currentLogo?: string;
  backgroundColor: string;
}

export const CompanyBrandingLogo: React.FC<Props> = ({
  currentLogo,
  backgroundColor,
}) => {
  const mutation = useLogoFileUpload();

  const handleOnAcceptedFiles = (acceptedFiles: File[]) => {
    mutation.mutate(acceptedFiles[0]);
  };

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      maxFiles: 1,
      maxSize: 1000000, //1mb in bytes;
      accept: {
        "image/jpeg": [".jpeg", ".jpg"],
        "image/png": [".png"],
      },
      multiple: false,
      onDropAccepted: handleOnAcceptedFiles,
    });

  /* istanbul ignore next */
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject],
  );

  return (
    <Flex alignItems="center" my={5} gap={5}>
      {!isEmpty(currentLogo) ? (
        <Image
          boxSize="150px"
          objectFit="contain"
          src={currentLogo}
          bgColor={backgroundColor}
          alt="currentLogo"
        />
      ) : null}
      <Flex
        boxSize="150px"
        data-testid="dropzone"
        sx={style}
        {...getRootProps()}
      >
        <input id="uploadFile" {...getInputProps()} />
        {mutation.isLoading ? (
          <Spinner />
        ) : (
          <Icon as={BsImage} boxSize="10" color="blue.600" my={2} />
        )}

        <Text color="grey.900" fontSize="sm" textAlign="center">
          Drop file here, or <Link color="blue.600">browse for it</Link>
        </Text>
      </Flex>
    </Flex>
  );
};

CompanyBrandingLogo.defaultProps = {};
